<template>
  <div class="paySuccess">
    <div class="icon_success">
      <img :src="img_url" alt />
    </div>
    <div class="success_tittle">
      <div class="tj">{{pay_status}}</div>
      <div class="what">如有问题可联系客服</div>
    </div>
  </div>
</template>

<script>
import { orderPaid } from "@/api/hexiao";
import img_success from "../../../static/imgs/is_success.png";
import img_fail from "../../../static/imgs/is_fail.png";

export default {
  data() {
    return {
      orderId: this.$route.query.orderId || "",
      mweb_url: this.$route.query.mweb_url || "",
      timer: "",
      img_url: img_success,
      pay_status: "等待支付...",
      count: 1
    };
  },
  components: {},
  created() {
    if (this.mweb_url) {
      window.location.href = this.mweb_url;
    }
    this.queryOrderPaid();
  },
  mounted() {
    this.setTimerNumber();
  },
  methods: {
    queryOrderPaid() {
      if (this.orderId) {
        orderPaid(this.orderId)
          .then(res => {
            if (res.data.pay_status == "SUCCESS") {
              this.img_url = img_success;
              this.pay_status = "支付成功";
              clearInterval(this.timer);
            } else if (res.data.pay_status == "FAIL") {
              clearInterval(this.timer);
              this.img_url = img_fail;
              this.pay_status = res.data.pay_msg;
            } else {
              this.img_url = img_fail;
              this.pay_status = res.data.pay_msg;
              if (this.count > 15) clearInterval(this.timer);
            }
          })
          .catch(err => {
            that.$toast.error(err);
          });
      }
    },
    //设置定时器
    setTimerNumber() {
      let that = this;
      that.timer = setInterval(function() {
        that.queryOrderPaid();
        that.count++;
      }, 1000);
    }
  }
};
</script>

<style lang="less" scoped>
.paySuccess {
  display: flex;
  // margin-top: 15px;
  margin: 80px auto;
  align-items: center;
  justify-content: center;
  .icon_success {
    height: 53px;
    width: 53px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .success_tittle {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    align-items: flex-start;
    .tj {
      height: 24px;
      font-size: 17px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
    }
    .what {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #cccccc;
      line-height: 20px;
    }
  }
}
</style>
